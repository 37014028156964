import React, { ReactElement } from "react";
import _ from "lodash";
import Grid from "@mui/material/Grid";
import ValidatorSelect from "../Validator/ValidatorSelect";
import ButtonLoading from "../Button/ButtonLoading";
import MenuItem from "@mui/material/MenuItem";
import ApiClient from "../../services/ApiClient";
import { useSnackbar } from "notistack";
import useForm from "../../hooks/useForm";
import { formValue } from "../../services/formServiceFunctions";
import { getCaseLink } from "../../services/Product/ProductService";
import ContentBox from "../ContentBox/ContentBox";
import { Box, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Stack } from "@mui/material";
import { queryKeys } from "../../services/ReactQuery/reactQueryService";
import { useQueryClient } from "@tanstack/react-query";
import { scrollToAnchor } from "../../theme/commonStyles";
import { AbstractCase } from "../../types/AbstractCase";
import { generatableFiles } from "../../services/generatableFilesService";
import GeneratableFile from "../../types/GeneratableFile";

type CaseFileGenerateFormProps = {
  product: AbstractCase;
  componentCfg?: {
    scrollToAnchor: string;
    title: string;
    postUrl: string;
    helperText: string;
    submitButton: (props: { isLoading: boolean }) => ReactElement;
    menuItems: { [key: string]: GeneratableFile[] };
  };
};

const defaultCaseFileGenerateCfg = {
  scrollToAnchor: "contractFiles",
  title: "Fertiger Schriftsatz",
  postUrl: "files/generate/",
  helperText: "Art der Datei: z.B. Rechnung alle Gebühren",
  submitButton: ({ isLoading }: { isLoading: boolean }) => (
    <ButtonLoading
      sx={{ width: "250px" }}
      isLoading={isLoading}
      disabled={isLoading}
      type={"submit"}
      color={"primary"}
      variant={"contained"}
    >
      Dokument generieren
    </ButtonLoading>
  ),
  menuItems: generatableFiles,
};

const CaseFileGenerateForm = ({ product, componentCfg = defaultCaseFileGenerateCfg }: CaseFileGenerateFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const onSubmit = async () => {
    const response = await ApiClient.post(componentCfg.postUrl + product.productClassName + "/" + product.id, {
      body: JSON.stringify({
        documentType: "pdf",
        documentIdentifier: values.fileType,
        sendEmailToCustomer: values.sendEmailToCustomer,
        showInGallery: values.showInGallery,
      }),
    });
    enqueueSnackbar(
      response && response.status === "ok"
        ? "Dokument wurde erfolgreich erstellt"
        : "Dokument konnte nicht erstellt werden",
      {
        variant: "custom",
        buttonType: "link",
        buttonLink: getCaseLink(product),
        buttonTarget: "_self",
        buttonText: "Zum Fall",
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
      }
    );
    await queryClient.invalidateQueries(queryKeys.collections("media_objects"));
  };

  const initialValues = { fileType: "", sendEmailToCustomer: false, showInGallery: false };
  const { values, handleChange, handleSubmit, isLoading } = useForm({
    initialValues,
    onSubmit,
  });

  const productGeneratableFiles = componentCfg.menuItems[product.productClassName];

  if (_.isEmpty(productGeneratableFiles)) {
    return null;
  }

  const IndividualSubmitButton = componentCfg.submitButton;

  return (
    <>
      <Box sx={scrollToAnchor} id={componentCfg.scrollToAnchor} />
      <ContentBox headline={componentCfg.title}>
        <form onSubmit={handleSubmit}>
          <Grid container alignItems={"center"} justifyContent={"center"} spacing={3}>
            <Grid item xs={12} md={6}>
              <ValidatorSelect
                label={"Welche Datei?"}
                name={"fileType"}
                value={formValue(values, "fileType")}
                helperText={componentCfg.helperText}
                onChange={handleChange}
                variant={"outlined"}
              >
                {_.map(productGeneratableFiles, (fileData: GeneratableFile) => {
                  return (
                    <MenuItem key={fileData.value} value={fileData.value} disabled={fileData.isDisabled(product)}>
                      {fileData.label}
                    </MenuItem>
                  );
                })}
              </ValidatorSelect>
            </Grid>
            <Grid item xs={12} md={6}>
              <Stack direction={"row"} justifyContent={"space-evenly"}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Für Mandantschaft sichtbar</FormLabel>
                  <RadioGroup row={true} name={"showInGallery"} value={values.showInGallery} onChange={handleChange}>
                    <FormControlLabel
                      disabled={values.sendEmailToCustomer === "true"}
                      value={"true"}
                      control={<Radio />}
                      label={"Ja"}
                    />
                    <FormControlLabel
                      disabled={values.sendEmailToCustomer === "true"}
                      value={"false"}
                      control={<Radio />}
                      label={"Nein"}
                    />
                  </RadioGroup>
                </FormControl>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Mandantschaft benachrichtigen</FormLabel>
                  <RadioGroup
                    row={true}
                    name={"sendEmailToCustomer"}
                    value={values.sendEmailToCustomer}
                    onChange={(e) => {
                      handleChange(e);
                      handleChange({ target: { name: "showInGallery", value: "true" } });
                    }}
                  >
                    <FormControlLabel value={"true"} control={<Radio />} label={"Ja"} />
                    <FormControlLabel value={"false"} control={<Radio />} label={"Nein"} />
                  </RadioGroup>
                </FormControl>
              </Stack>
            </Grid>
            <Grid item>{<IndividualSubmitButton isLoading={isLoading} />}</Grid>
          </Grid>
        </form>
      </ContentBox>
    </>
  );
};

export default CaseFileGenerateForm;
